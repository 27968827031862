var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v("Site İstatistik Bilgisi")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'sayfa-listele',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"BookIcon","statisticTitle":"Sayfa İçerik","statistic":_vm.data.sayfalar,"color":"primary"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'slider',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"ImageIcon","statisticTitle":"Slider İçerik","statistic":_vm.data.slider,"color":"info"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'urun',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"ShoppingCartIcon","statisticTitle":"Ürün İçerik","statistic":_vm.data.urunler,"color":"success"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'rezervasyonlar',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"LayersIcon","statisticTitle":"Rezervasyonlar","statistic":_vm.data.rezervasyonlar,"color":"primary"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'yorumlar',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"TwitchIcon","statisticTitle":"Yorumlar","statistic":_vm.data.yorumlar,"color":"info"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('router-link',{attrs:{"to":{
          name: 'uyeler',
        }}},[_c('static-widget',{attrs:{"show":_vm.show,"icon":"UsersIcon","statisticTitle":"Üyeler","statistic":_vm.data.uyeler,"color":"success"}})],1)],1)],1),_c('h4',{staticClass:"mb-2 mt-3"},[_vm._v("Finan Durum Bilgisi")]),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"xl":"4","md":"6"}},[_c('default-price-total',{attrs:{"show":_vm.show,"price":_vm.data.price}})],1),_c('b-col',{attrs:{"xl":"8","md":"6"}},[_c('other-price-total',{attrs:{"show":_vm.show,"data":_vm.data.otherPrice}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('latest-comments',{attrs:{"show":_vm.show,"data":_vm.data.sonYorumlar}})],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('most-reservations',{attrs:{"show":_vm.show,"rows":_vm.data.enSonGelenRezervasyonlar}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }