<template>
  <b-card no-body class="rounded-0">
    <b-overlay variant="white" :show="show" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card-header>
        <div class="d-flex align-items-center">
          <feather-icon size="24" class="mr-1" icon="ListIcon" />
          <b-card-title class="ml-25">En Son Gelen Yorumlar </b-card-title>
        </div>
        <b-button squared size="sm" variant="outline-primary"> Tümü</b-button>
      </b-card-header>

      <!-- timeline -->
      <b-card-body>
        <app-timeline>
          <app-timeline-item variant="primary" v-for="item in data" :key="item._id">
            <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
              <h6>{{ item.ad_soyad }}</h6>
              <small class="timeline-item-time text-nowrap text-muted ml-1">{{ item.save_date | momentFull }}</small>
            </div>
            <p class="mb-0">{{ item.mesaj }}</p>
            <b-media no-body>
              <b-media-body class="my-auto">
                <small class="media-body mt-0">{{ item.e_mail }}</small>
              </b-media-body>
            </b-media>
          </app-timeline-item>
        </app-timeline>
        <!--/ timeline -->
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
/* eslint-disable global-require */
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    show: [Boolean],
  },

  data() {
    return {};
  },
};
</script>
